import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

import { StorageService } from './../../services/storage.service';
import { AppConstants } from './../../config/app-constants';
import { PersonService } from '../../services/person.service';

import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-member-details',
  templateUrl: './edit-member-details.component.html',
  styleUrls: ['./edit-member-details.component.scss'],
})
export class EditMemberDetailsComponent implements OnInit {
  @Input() person: any;
  image_url:string;
  authUser;
  isLoggedin = false;
  nav_isLoggedin='';
  person_id;
  mapURL;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    public sanitizer: DomSanitizer,
    private personService: PersonService,
  ) { }

  ngOnInit() {
    this.image_url = environment.apiUrl + 'assets/images/members/';
    this.person_id = this.route.snapshot.paramMap.get('id') == null ? 0 : Number(this.route.snapshot.paramMap.get('id'));
    this.storageService.get(AppConstants.AUTH).then(res => {
      if(res){
        this.authUser = res;
        this.isLoggedin = this.authUser.id ? true : false;
        this.nav_isLoggedin = this.isLoggedin == true ? '/home' : '';
      }

    });

    //Collect Person in local storage
    this.storageService.get(AppConstants.Person).then((person_res:any) => {
      if (person_res != null) {
          
        if(person_res.id = this.person_id){
          this.person = person_res;
        }else{
          this.get_person_info(this.person_id);
        } 
          console.log('person2', this.person);

          if(this.person?._details.latitude != null){
            let map_url= "https://www.google.com/maps/embed/v1/place?key=AIzaSyDVn5vR_W952pZIMlxPia-wTzQjkOUZzGk&zoom=13&q=" + this.person._details.latitude +"," + this.person._details.longitude;
            this.mapURL = this.sanitizer.bypassSecurityTrustResourceUrl(map_url);
          } 

      }else{
        this.router.navigate(['/home/edit-member/'+ this.person.id]);
      }


      
    });


  }
  
    get_person_info(id){
      //this.loading.present();
      this.personService.getMember(id).subscribe(data => {
      this.person = data.current_member;
      this.storageService.store(AppConstants.Person, this.person);
      console.log('person_edit', this.person);
      //this.loading.dismiss();
      },
      error => {
        console.log('get_member_error',error);
        //this.loading.dismiss();
      });
    }
  

}
