import { Injectable } from '@angular/core';
//import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';
import { AppConstants } from './../config/app-constants';
//import { Answer } from './models/answer.model';
//import { Question } from './models/question.model';
//import { Member } from '../models/Member';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  formData = new FormData();
  token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjEifQ.IsWkez9DuDRnxzSsgkW418QgdP6vr9Z3qvIHTfaK5Ss';
  personData$ = new BehaviorSubject<any>([]);

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private storageService: StorageService,
    ) {
    
    this.formData.append('token', this.token);
  }
  
  getPersonData() {
    this.storageService.get(AppConstants.Person).then(res => {
      this.personData$.value(res);
    });
  }
  

  getMember(id): Observable<any> {
    let formData = new FormData();
      formData.append('token', this.token);
      formData.append('member_id', id);
      //return this.http.post<any[]>(environment.apiUrl + 'member/read', formData);
      return this.httpService.post('member/read', formData);
  }

  getDetails(id): Observable<any> {
    //this.formData.append('member_id', id);
    return this.http.get<any>(environment.apiUrl + 'details/view/mid/' + id + '/?token=' + this.token);
    //return this.httpService.get('member/read', formData);
  }
  
  get_children(person): Observable<any> {
    let formData = new FormData();
    formData.append('token', this.token);
    formData.append('cur_member', JSON.stringify(person));
    //return this.http.post<any[]>(environment.apiUrl + 'member/get_children', formData);
    return this.httpService.post('member/get_children', formData);  
  }

  get_spouse(person): Observable<any> {
    let formData = new FormData();
    formData.append('token', this.token);
    formData.append('cur_member', JSON.stringify(person));
    //return this.http.post<any[]>(environment.apiUrl + 'member/get_spouse', formData);
    return this.httpService.post('member/get_spouse', formData);
  }

  findMember(params): Observable<any> {
    let formData = new FormData();
      formData.append('params', JSON.stringify(params));
      //return this.http.post<any[]>(environment.apiUrl + 'appmember/search_members', formData);
      return this.httpService.post('member/search_members', formData);
  }

  add_child(new_member, cur_member): Observable<any> {
    let formData = new FormData();


    formData.append('token', this.token);
    formData.append('cur_member', JSON.stringify(cur_member));
    formData.append('member', JSON.stringify(new_member));
      //return this.http.post<any>(environment.apiUrl + 'member/add_member', formData);
      return this.httpService.post('member/add_member', formData);
  }

  delete_child(person_id): Observable<any> {
    let formData = new FormData();
    formData.append('token', this.token);
    formData.append('member_id', person_id);

      //return this.http.post<any>(environment.apiUrl + 'member/add_member', formData);
      return this.httpService.post('member/delete_member', formData);
  }

  find_spouse(name, cur_member): Observable<any> {
    let formData = new FormData();
    formData.append('token', this.token);
    formData.append('name', name);
    formData.append('cur_member', JSON.stringify(cur_member));

      //return this.http.post<any>(environment.apiUrl + 'member/add_member', formData);
      return this.httpService.post('member/search_spouse', formData);
  }

  add_spouse(cur_member, spouse_id): Observable<any> {
    let formData = new FormData();
    formData.append('token', this.token);
    formData.append('spouse_id', spouse_id);
    formData.append('cur_member', JSON.stringify(cur_member));

      //return this.http.post<any>(environment.apiUrl + 'member/add_member', formData);
      return this.httpService.post('member/add_spouse', formData);
  }

  delete_relationship(cur_member, relationship, relationship_id ): Observable<any> {
    let formData = new FormData();
    formData.append('token', this.token);
    formData.append('cur_member', JSON.stringify(cur_member));
    formData.append('rel_id', relationship_id);
    formData.append('relationship', relationship);

      //return this.http.post<any>(environment.apiUrl + 'member/add_member', formData);
      return this.httpService.post('member/delete_relationship', formData);
  }

  add_parent(cur_member, relationship, relationship_id ): Observable<any> {
    let formData = new FormData();
    formData.append('token', this.token);
    formData.append('cur_member', JSON.stringify(cur_member));
    formData.append('rel_id', relationship_id);
    formData.append('relationship', relationship);

      //return this.http.post<any>(environment.apiUrl + 'member/add_member', formData);
      return this.httpService.post('member/add_parent', formData);
  }

  update_details(member_details, cur_member): Observable<any> {
    let formData = new FormData();


    formData.append('token', this.token);
    formData.append('cur_member', JSON.stringify(cur_member));
    formData.append('member_details', JSON.stringify(member_details));
      //return this.http.post<any>(environment.apiUrl + 'member/add_member', formData);
      return this.httpService.post('details/update/' + cur_member.id, formData);
  }

  //https://drjliveevents.in/myfamily/member/delete_relationship add_spouse

}
